export const zodiacs = [
  { value: 'ราศีเมษ', title: 'ราศีเมษ (13 เม.ษ. - 14 พ.ค.)' },
  { value: 'ราศีพฤษภ', title: 'ราศีพฤษภ (15 พ.ค. - 14 มิ.ย.)' },
  { value: 'ราศีเมถุน', title: 'ราศีเมถุน (15 มิ.ย. - 14 ก.ค.)' },
  { value: 'ราศีกรกฎ', title: 'ราศีกรกฎ (15 ก.ค. - 15 ส.ค.)' },
  { value: 'ราศีสิงห์', title: 'ราศีสิงห์ (16 ส.ค. - 16 ก.ย.)' },
  { value: 'ราศีกันย์', title: 'ราศีกันย์ (17 ก.ย. - 16 ต.ค.)' },
  { value: 'ราศีตุลย์', title: 'ราศีตุลย์ (17 ต.ค. - 15 พ.ย.)' },
  { value: 'ราศีพิจิก', title: 'ราศีพิจิก (16 พ.ย. - 15 ธ.ค.)' },
  { value: 'ราศีธนู', title: 'ราศีธนู (16 ธ.ค. - 14 ม.ค.)' },
  { value: 'ราศีมังกร', title: 'ราศีมังกร (15 ม.ค. - 12 ก.พ.)' },
  { value: 'ราศีกุมภ์', title: 'ราศีกุมภ์ (13 ก.พ. - 14 มี.ค.)' },
  { value: 'ราศีมีน', title: 'ราศีมีน (15 มี.ค. - 12 เม.ย.)' }
]
