import { loadEnv } from './utils/load-env'

export const IS_PROD = import.meta.env.PROD

/** API service URL endpoint */
export const API_URL = loadEnv('VITE_API_URL', String)
  .required()
  .load(() => import.meta.env.VITE_API_URL)

export const GBPAY_URL = loadEnv('VITE_GBPAY_URL', String)
  .required()
  .load(() => import.meta.env.VITE_GBPAY_URL)

export const BASE_URL = loadEnv('VITE_BASE_URL', String)
  .required()
  .load(() => import.meta.env.VITE_BASE_URL)

export const APP_ENV = loadEnv('VITE_APP_ENV', String)
  .required()
  .load(() => import.meta.env.VITE_APP_ENV)

export const LIFF_ID = loadEnv('VITE_LIFF_ID', String)
  .required()
  .load(() => import.meta.env.VITE_LIFF_ID)

export const GTM_ID = loadEnv('VITE_GTM_ID', String)
  .required()
  .load(() => import.meta.env.VITE_GTM_ID)

export const APM_APPLICATION_NAME = loadEnv('VITE_APM_APPLICATION_NAME', String)
  .required()
  .load(() => import.meta.env.VITE_APM_APPLICATION_NAME)

export const APM_SERVER_URL = loadEnv('VITE_APM_SERVER_URL', String)
  .required()
  .load(() => import.meta.env.VITE_APM_SERVER_URL)
