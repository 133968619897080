import { api } from '@/apis/configs/axiosConfigs'

export const CouponAPI = {
  getCoupon: async function(couponCode: string) {
    const response = await api.request({
      url: `/coupons/${couponCode}`,
      method: 'GET',
    })

    return response.data
  },
  
  updateCoupon: async function (couponCode: string) {
    const response = await api.request({
      url: `/coupons`,
      method: 'PATCH',
      data: {
        coupon: couponCode,
      }
    })
    return response.data
  }
}
