interface CircleProps {
    fill: string
    stroke: string
    width: string
    height: string
}

const Circle = ({fill, stroke, width, height}: CircleProps) => {
    // 👇️ paste SVG into a component
    // take fill and stroke colors as props
    return (
      <svg fill={fill} stroke={stroke} width={width} height={height}>
        <circle cx="50%" cy="50%" r="1rem" strokeWidth="2" />
      </svg>
    );
  }
  export default Circle