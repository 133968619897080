import { UserContext } from "./types"

interface AddUserInfoAction {
  type: UserActionTypes.AddUserInfo
  payload: UserContext
}

interface AddPaymentIdAction {
  type: UserActionTypes.AddUserInfo
  payload: UserContext
}

export enum UserActionTypes {
  AddUserInfo = 'ADD_USER_INFO',
  AddPaymentId = 'Add_PAYMENT_ID'
}

export type UserActions = AddUserInfoAction | AddPaymentIdAction
