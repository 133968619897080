import { Zodiac } from '@/providers/user/types'

export const calculateZodiac = (date: Date): Zodiac => {
  interface Conditions {
    index: number
    name: string
    date: number
  }

  let conditions: Conditions[], zodiacs: string[]
  conditions = [
    { index: 0, name: 'December', date: 16 },
    { index: 1, name: 'January', date: 15 },
    { index: 2, name: 'February', date: 13 },
    { index: 3, name: 'March', date: 15 },
    { index: 4, name: 'April', date: 13 },
    { index: 5, name: 'May', date: 15 },
    { index: 6, name: 'June', date: 15 },
    { index: 7, name: 'July', date: 15 },
    { index: 8, name: 'August', date: 16 },
    { index: 9, name: 'September', date: 17 },
    { index: 10, name: 'October', date: 17 },
    { index: 11, name: 'November', date: 16 }
  ]
  zodiacs = [
    'ราศีพิจิก',
    'ราศีธนู',
    'ราศีมังกร',
    'ราศีกุมภ์',
    'ราศีมีน',
    'ราศีเมษ',
    'ราศีพฤษภ',
    'ราศีเมถุน',
    'ราศีกรกฎ',
    'ราศีสิงห์',
    'ราศีกันย์',
    'ราศีตุลย์'
  ]
  let zodiac: string,
    month = date.toLocaleString('en', { month: 'long' }),
    day = date.getDate(),
    condition: Conditions = conditions.find(i => i.name == month)!

  zodiac =
    day < condition.date
      ? zodiacs[condition.index]
      : zodiacs[condition.index + 1]

  if (!zodiac) return <Zodiac>zodiacs[0]

  return <Zodiac>zodiac
}
