import { PackageContext } from "./types"

interface InformationPackageAction {
  type: PackageActionTypes.Select,
  payload: PackageContext[]
}
export enum PackageActionTypes {
  Select = 'SELECT'
}

export type PackageActions = InformationPackageAction
