import { PaymentContext } from './types'

interface SetGBPayKeysAction {
  type: PaymentActionTypes.SetGBPayKeys
  payload: PaymentContext
}

interface SubmitPaymentAction {
  type: PaymentActionTypes.SubmitPayment
  payload: PaymentContext
}

interface CreatePaymentIDAction {
  type: PaymentActionTypes.CreatePaymentID
  payload: PaymentContext
}

export enum PaymentActionTypes {
  SubmitPayment = 'SUBMIT_PAYMENT',
  CreatePaymentID = 'CREATE_PAYMENT_ID',
  SetGBPayKeys = 'SET_GBPAY_KEYS'
}

export type PaymentActions = SubmitPaymentAction | CreatePaymentIDAction | SetGBPayKeysAction
