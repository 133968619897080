import { api } from './configs/axiosConfigs'

import { PackageContext } from '@/providers/package/types'

export const PackageAPI = {
  getById: async function (galleryId: string): Promise<PackageContext> {
    const response = await api.request({
      url: '/packages',
      method: 'GET',
      params: {galleryId: galleryId}
    })

    return response.data
  }
}
