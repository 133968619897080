import { useEffect, useState } from 'react'
import { InfinitySpin } from 'react-loader-spinner'
import { Navigate } from 'react-router-dom'

import { inAppRouteList } from '@/constants'
import { PaymentAPI } from '@/apis/paymentAPI'
import CreditCardInput from '@/components/payment/credit-card/creditCardInput'
import Charge from '@/components/payment/credit-card/summary'
import ThreeDSecure from '@/components/payment/credit-card/threeDSecure'
import QrCode from '@/components/payment/qr-code'
import {
  PaymentActionTypes,
  usePaymentContext,
  UserActionTypes,
  useUserContext
} from '@/providers'
import { CardDetail, PaymentStep } from '@/providers/payment/types'
import { getUserInfo } from '@/utils/localStorage'

const PaymentGatewayPage = () => {
  const { context: paymentContext, dispatch: paymentDispatch } = usePaymentContext()
  const { dispatch: userDispatch } = useUserContext()

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [step, setStep] = useState<PaymentStep>(paymentContext.type)
  const [token, setToken] = useState('')
  const [gbpReferenceNo, setGbpReferenceNo] = useState('')
  const [cardDetail, setCardDetail] = useState<CardDetail>({
    number: '',
    expirationMonth: '',
    expirationYear: '',
    securityCode: '',
    customerName: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const [image, setImage] = useState('')

  if (!paymentContext.type) return <Navigate to={inAppRouteList.ProductList} />

  const handleCardDetail = (detail: CardDetail) => {
    setCardDetail(detail);
  };

  const handleLoading = (value: boolean) => {
    setIsLoading(value);
  };

  const handleStep = (value: PaymentStep) => {
    setStep(value);
  };

  const handleGbpReferenceNo = (value: string) => {
    setGbpReferenceNo(value);
  };

  const handleToken = (value: string) => {
    setToken(value);
  };

  useEffect(() => {
    if (params.ref) {
      const updatedValues = {
        ...paymentContext, 
        ...{ 
          status: 'SUCCESSFUL', 
          resultCode: params.code, 
          updatedAt: new Date(),
          id: params.id,
          referenceNo: params.ref,
          amount: parseInt(params.amount)
        } 
      }
      paymentDispatch({ type: PaymentActionTypes.SubmitPayment, payload: { ...updatedValues } })

      const userData = getUserInfo()

      if (!userData) return

      const userInfo = JSON.parse(userData)
      userDispatch({ type: UserActionTypes.AddUserInfo, payload: {...userInfo }})
      setStep('success')
    }
  }, [])

  const getQrCode = async () => {
    setIsLoading(true)

    if (!!paymentContext.referenceNo && !!paymentContext.amount) {
      const data = new FormData()
      data.append('token', paymentContext.customer_key)
      data.append('referenceNo', paymentContext.referenceNo)
      data.append('amount', paymentContext.amount.toFixed(2))

      const response = await PaymentAPI.getQRCode(data, paymentContext.public_key)

      if (response) {
        setImage(response)
        setStep('qr-status')
        setIsLoading(false)
      }
    }
  }

  const getQrStatus = () => {
    return <QrCode image={image} handleStep={handleStep}></QrCode>
  }

  const getCardDetail = () => {
    return <CreditCardInput token={token} cardDetail={cardDetail} handleCardDetail={handleCardDetail} handleLoading={handleLoading} handleStep={handleStep} handleToken={handleToken} ></CreditCardInput>
  }

  const getSummary = () => {
    return <Charge token={token} cardDetail={cardDetail} handleLoading={handleLoading} handleStep={handleStep} handleGbpReferenceNo={handleGbpReferenceNo}></Charge>
  }

  const get3DSecure = () => {
    return <ThreeDSecure gbpReferenceNo={gbpReferenceNo}></ThreeDSecure>
  }

  const getSpinner = () => {
    return (
      <>
        <div className='flex justify-around items-center w-full h-screen'>
          <InfinitySpin
            width='200'
            color='#4fa94d'
          />
        </div>
      </>
    )
  }

  return (
    <>
      {isLoading && getSpinner()}
      {(step == 'qr' && !isLoading) && getQrCode()}
      {(step == 'qr-status' && !isLoading) && getQrStatus()}
      {(step == 'credit' && !isLoading) && getCardDetail()}
      {(step == 'credit-charge' && !isLoading) && getSummary()}
      {(step == 'credit-3d-secure' && !isLoading) && get3DSecure()}
    </>
  )
}

export default PaymentGatewayPage
