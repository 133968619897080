class EnvLoaderBuilder<T> {
  private _required: boolean = false
  private _defaultValue?: T

  constructor(public name: string, public type: (value?: string) => T) {}

  required() {
    this._required = true
    return this
  }

  defaultValue(value: T) {
    this._defaultValue = value
    return this
  }

  /**
   * Ref: https://www.youtube.com/watch?v=DmvOmjjH2Fk
   */
  load(loader: () => string | undefined): T
  load(loader: () => Promise<string | undefined>): Promise<T>
  load(
    loader: () => string | undefined | Promise<string | undefined>
  ): T | Promise<T> {
    const value = loader()

    if (typeof value === 'string' && value.length > 0) {
      return this.type(value)
    }

    if (value instanceof Promise) {
      return value.then(v => this.load(() => v))
    }

    if (this._required) {
      throw new Error(
        `environment variable "${this.name}" is required but missing!`
      )
    }

    if (!this.isT(this._defaultValue)) {
      throw new Error(
        `please provide "defaultValue" for environment variable "${this.name}"`
      )
    }

    return this._defaultValue
  }

  private isT(x?: T): x is T {
    return typeof x !== 'undefined'
  }
}

export const loadEnv = <T>(
  ...args: ConstructorParameters<typeof EnvLoaderBuilder<T>>
) => new EnvLoaderBuilder(...args)