import { PaymentAPI } from '@/apis/paymentAPI'
import { TransactionAPI } from '@/apis/transactionAPI'
import { PaymentActionTypes, usePaymentContext } from '@/providers'
import { PaymentStep } from '@/providers/payment/types'
import { addDays, format } from 'date-fns'
import { useEffect } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import light from '@/assets/images/light2.svg'
interface QrCodeProps {
  image: string;
  handleStep: (value: PaymentStep) => void;
}

const SUCCESS_STATUS = ['S']
const FAILED_STATUS = ['V', 'D']
const PAYMENT_SUCCESS_STATUS = 'SUCCESSFUL'
const PAYMENT_FAILED_STATUS = 'FAILURE'

const QrCode = ({ image }: QrCodeProps) => {
  const navigate = useNavigate()

  const { context: paymentContext, dispatch: paymentDispatch } = usePaymentContext()

  const currentDate = new Date()

  const fetchStatus = async () => {
    const response = await PaymentAPI.getQRStatus({ referenceNo: paymentContext.referenceNo }, paymentContext.secret_key)

    const result = response.txns ? response.txns[0] : response.txn

    // TODO: handle other status than failed and success
    let status = ''

    if (SUCCESS_STATUS.includes(result.status)) status = PAYMENT_SUCCESS_STATUS
    if (FAILED_STATUS.includes(result.status)) status = PAYMENT_FAILED_STATUS

    if (status) {
      const updatedValues = {
        ...paymentContext,
        ...{
          status: status,
          resultCode: result.resultCode,
          updatedAt: new Date()
        }
      }

      paymentDispatch({ 
        type: PaymentActionTypes.SubmitPayment, 
        payload: { ...updatedValues } 
      })

      const query = {
        reference_no: paymentContext.referenceNo
      }

      const updateData = { status }

      await TransactionAPI.updateTransaction(
        paymentContext.id,
        query,
        updateData,
      )

      if (status === PAYMENT_SUCCESS_STATUS) {
        navigate({
          pathname: '/payment-successful',
          search: createSearchParams({
            type: 'qr',
            ref: `${paymentContext.referenceNo}`,
            code: `${result.resultCode}`,
            amount: `${paymentContext.amount}`,
            id: `${paymentContext.id}`
          }).toString()
        })
      }
      
      if (status === PAYMENT_FAILED_STATUS) {
        navigate({
          pathname: '/payment',
          search: createSearchParams({
            type: paymentContext.type,
            code: result.resultCode
          }).toString()
        })
      }
    }
  }

  useEffect(() => {
    const intervalID = setInterval(() => {
      fetchStatus();
    }, 5000);

    return () => clearInterval(intervalID);
  }, []);

  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center text-sm">
        <div className="max-w-[420px] bg-neutral-100 shadow-xl">
          <div className="p-2">
            <div className="flex flex-row justify-between p-1">
              <div>ยอดชำระเงินทั้งหมด:</div>
              <div className='text-gray-500'>THB {paymentContext.amount.toFixed(2)}</div>
            </div>
            <div className="flex flex-row justify-between p-1">
              <div>กรุณาชำระภายใน:</div>
              <div className='text-gray-500'>{format(addDays(currentDate, 1), 'dd-MM-yyyy HH:mm')}</div>
            </div>
            <div className="flex flex-row justify-between p-1">
              <div>รหัสอ้างอิง:</div>
              <div className='text-gray-500'>{paymentContext.referenceNo}</div>
            </div>
            <div className="flex bg-gray-200 flex-row justify-center p-2">
              <div className='w-[16px] h-[16px] mr-1'><img src={light} alt="" /></div>
              <div className="text-sm font-medium text-center">
                แคปเจอร์ภาพหน้าจอ และชำระเงินผ่าน QR Code
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-center items-center m-2'>
            <img src={image} alt='qrcode' />
          </div>
        </div>
      </div>
    </>
  )
}

export default QrCode
