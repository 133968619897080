import { 
  createContext, 
  useContext, 
  useReducer, 
  Dispatch, 
  PropsWithChildren 
} from 'react'

import { PackageActionTypes, PackageActions } from './action'
import { PackageContext } from '@/providers/package/types'

const defaultPackageContext: PackageContext[] = []
const PackageContext = createContext<{
  context: PackageContext[]
  dispatch: Dispatch<PackageActions>
} | null>(null)

const reducer = (context: PackageContext[], { type, payload }: PackageActions) => {
  switch (type) {
    case PackageActionTypes.Select:
      return payload
    default:
      throw new Error('invalid action')
  }
}

export const usePackageContext = () => {
  const ctx = useContext(PackageContext)

  if (!ctx) throw new Error('usePackageContext can only be used in PackageContext')

  return ctx
}

export const PackageProvider = ({ children }: PropsWithChildren) => {
  const [context, dispatch] = useReducer(reducer, defaultPackageContext)

  return (
    <PackageContext.Provider value={{ context, dispatch }}>
      {children}
    </PackageContext.Provider>
  )
}
