import { api } from '@/apis/configs/axiosConfigs'

export const TransactionAPI = {
  createTransaction: async function(data: object) {
    const response = await api.request({
      url: `/transactions/`,
      method: 'POST',
      data: data,
    })

    return response.data.payment_id
  },
  
  updateTransaction: async function (
    id: string,
    query: object,
    updateData: object
  ) {
    const response = await api.request({
      url: `/transactions/${id}`,
      method: 'PATCH',
      data: {
        query: query,
        updateData: updateData
      }
    })
    return response.data
  }
}
