import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

const DownloadPage = () => {
  const [searchParams] = useSearchParams()
  const [errMsg, setErrMsg] = useState('')

  const imgName = searchParams.get('imgName') || ''
  const imgUrl = searchParams.get('imgUrl') || ''

  if (!imgName || !imgUrl) {
    setErrMsg('ข้อมูลในการดาวน์โหลดไม่ถูกต้อง')
  }

  const handleDownloadClick = async (e?: React.MouseEvent) => {
    try {
      if (e) {
        e.preventDefault();
      }
      if (!imgUrl) throw new Error('no image')
      const link = document.createElement('a')
      link.href = decodeURIComponent(imgUrl)
      link.download = `${imgName}.png`
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      setErrMsg('พบปัญหาขณะดาวน์โหลด')
    }
  }

  useEffect(() => {
    handleDownloadClick()
  }, [])

  return (
    <div className="flex flex-row justify-center items-center h-[70vh]">
      <div className="text-md font-bold text-center">
        {errMsg ? (
          `การดาวน์โหลดไม่สำเร็จ ${errMsg}`
        ) : (
          <>
            การดาวน์โหลดสำเร็จ <br />
            Wallpaper ของคุณ Save ใน Photo แล้ว
          </>
        )}
      </div>
    </div>
  )
}

export default DownloadPage
