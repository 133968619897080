import { useEffect, useState } from 'react'
import { useParams, NavLink, Navigate } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import NotFoundError from '@/components/common/notFoundError'

import { inAppRouteList } from '@/constants'
import { getToItemListUrl } from '@/routes/helper'
import { useGalleryContext, GalleryActionTypes } from '@/providers/gallery'
import { GalleryContext } from '@/providers/gallery/types'
import { GalleryAPI } from '@/apis/galleryAPI'

const ProductPage = () => {
  const { productId } = useParams()
  
  if (!productId) return <Navigate to={inAppRouteList.ProductList} />

  const [gallery, setGallery] = useState<GalleryContext | null>(null)

  const { context: galleryContext, dispatch: galleryDispatch } = useGalleryContext()

  const initGalleryContext = async () => {
    try {
      const data = await GalleryAPI.fetchGalleryByID(productId)

      setGallery(data)

      galleryDispatch({
        type: GalleryActionTypes.ShowDetail,
        payload: [data]
      })
    } catch (e) {
      return <NotFoundError msg="Gallery" cta={true} />
    }
  }

  useEffect(() => {
    const data = galleryContext.find(g => g._id === productId)

    if (!(data && data.title)) {
      // when galleryContext is invalid then we have to fetch gallery by id
      initGalleryContext()
    } else {
      // when galleryContext is valid
      setGallery(data)
    }
  }, [])

  return (
    // similar with product list page, size of single bg should be 100vh to fit a mobile screen
    // we can consider that gallery.imageInformation[0] should be array or not
    <>
      <div className="pb-[80px]">
        {
          gallery && gallery.imageInformation.map((image: string, imageIndex: number) => {
            return (
              <section
                style={{
                  backgroundImage: `url(${image})`
                }}
                className="bg-cover w-[100vw] h-[calc(100vh-48px)] bg-fix relative bg-no-repeat bg-center mb-2"
                key={imageIndex}
              ></section>
            )
          })
        }
      </div>
      <div className="fixed bottom-0 rounded-t-2xl h-[80px] flex flex-col justify-center w-full bg-white shadow-next-button">
        <div className="m-2">
          <Button
            className="bg-dark-red hover:bg-dark-red min-w-[200px] w-full rounded-md"
            id={`btn-continue-${gallery?.galleryName}`}
            asChild
          >
            <NavLink
              to={getToItemListUrl(`${productId}`)}
            >
              <div className="font-bold text-sm text-gold">
                ต่อไป
              </div>
            </NavLink>
          </Button>
        </div>
      </div>
    </>
  )
}

export default ProductPage
