import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import liff from '@line/liff'

import { CouponAPI } from '@/apis/couponAPI'
import { ImageAPI } from '@/apis/imageApis'
import { getUserInfo, getPaymentId, getUsedCoupon, delUsedCoupon } from '@/utils/localStorage'
import {
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTrigger,
  Button
} from '@/components/ui'
import Spinner from '@/components/common/spinner'
import line from 'src/assets/social/line.png'

const PaymentSuccessPage = () => {
  const [imgUrl, setImgUrl] = useState('')
  const [imgName, setImgName] = useState('rapsap')
  const [errMsg, setErrMsg] = useState('')
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  // TODO: refactor this code to support QR callback
  const paymentId = searchParams.get('id') || getPaymentId()

  const fetchPresignedUrl = async () => {
    try {
      setIsLoading(true)

      const userData = getUserInfo()

      if (!userData) {
        throw new Error('no user information')
      }

      const userInfo = JSON.parse(userData)
      const presignedUrl = await ImageAPI.getPresignedUrl(
        userInfo.galleryName,
        userInfo.imageDirectory,
        userInfo.uid,
        paymentId,
        `${userInfo.firstName} ${userInfo.lastName}`
      )
      if (!presignedUrl) {
        throw new Error('Payment failed')
      }
      setImgUrl(presignedUrl)
      setImgName(userInfo.galleryName)
    } catch (e) {
      if (e instanceof Error) {
        // TODO: add contact us modal to handle error
        setErrMsg(e.toString())
      }
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadClick = async () => {
    try {
      if (!imgUrl) throw new Error('no image')

      const externalUrl = `${window.location.origin}/download?imgName=${imgName}&imgUrl=${encodeURIComponent(imgUrl)}&openExternalBrowser=1`

      await liff.openWindow({
        url: externalUrl,
        external: true
      })
    } catch (e) {
      if (e instanceof Error) {
        // TODO: add contact us modal to handle error
        setErrMsg(e.toString())
      }
    }
  }

  const updateCouponStatus = async () => {
    if (!getUsedCoupon()) return

    try {
      await CouponAPI.updateCoupon(getUsedCoupon())
    } catch (e) {
      console.error(e)
    }

    delUsedCoupon()
  }

  useEffect(() => {
    if (!paymentId) {
      setErrMsg('พบปัญหาในการจ่ายเงิน กรุณาติดต่อทีมงาน')
    }

    updateCouponStatus()
    fetchPresignedUrl()
  }, [])

  useEffect(() => {
    if (imgUrl){
      handleDownloadClick()
    }
  }, [imgUrl])

  if (errMsg) {
    return (
      <div className="flex flex-row justify-center items-center h-[70vh]">
        <div className="text-md font-bold p-6">{ errMsg } <br/>รหัสอ้างอิง: { getPaymentId() }</div>
      </div>
    )
  }

  if (isLoading) return <Spinner />

  return (
    <>
      <div className="flex flex-col my-4 items-center justify-center p-4">
        <p className="font-medium text-xl text-center">กด Save ภาพวอลล์เปเปอร์ของท่านได้เลย</p>
      </div>
      <Card className="w-[350px] bg-neutral-100 py-6 shadow-xl">
        <CardContent className="grid gap-4">
          <Dialog>
            <DialogTrigger asChild>
              <div className="flex flex-col justify-center items-center mb-2">
                <img
                  src={imgUrl}
                  alt="wallpaper"
                  className="pointer-events-none max-h-[300px]"
                />
              </div>
            </DialogTrigger>
            <DialogContent className="aspect-video md:aspect-square justify-center">
              <img
                id='my-canvas'
                src={imgUrl}
                alt="wallpaper"
                className="h-[80vh]"
              />
              {
                imgUrl && (
                  <Button
                    className="bg-dark-red hover:bg-dark-red min-w-[200px] w-full rounded-md font-bold text-sm text-gold"
                    onClick={handleDownloadClick}
                  >
                    ดาวน์โหลด
                  </Button>
                )
              }
            </DialogContent>
          </Dialog>
        </CardContent>
      </Card>
      <div className="flex flex-col justify-center items-center">
        <div className="font-medium text-md px-6 py-2">หากมีปัญหาเกี่ยวกับการชำระเงิน หรือ ไฟล์ภาพ ติดต่อได้ที่</div>
        <div>
          <a href="https://lin.ee/WYKUvrp">
            <img src={line} style={{ height: '45px' }} />
          </a>
        </div>
      </div>
    </>
  )
}

export default PaymentSuccessPage
