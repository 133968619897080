const USER_INFO = 'userInfo'
const PAYMENT_ID = 'paymentId'
const USED_COUPON = 'usedCoupon'

export const getUserInfo = () => localStorage.getItem(USER_INFO)
export const setUserInfo = (info: string) => localStorage.setItem(USER_INFO, info)

export const getPaymentId = (): string => localStorage.getItem(PAYMENT_ID) || ''
export const setPaymentId = (id: string) => localStorage.setItem(PAYMENT_ID, id)

export const getUsedCoupon = (): string => localStorage.getItem(USED_COUPON) || ''
export const setUsedCoupon = (code: string) => localStorage.setItem(USED_COUPON, code)
export const delUsedCoupon = (): void => localStorage.removeItem(USED_COUPON)