import { GalleryContext } from "./types"

interface InformationGalleryAction {
  type: GalleryActionTypes.ShowDetail,
  payload: GalleryContext[]
}
export enum GalleryActionTypes {
  ShowDetail = 'SHOW_DETAIL'
}

export type GalleryActions = InformationGalleryAction
