import { api } from '@/apis/configs/axiosConfigs'

export const ImageAPI = {
  get: async function(productId: string, itemId: string) {
    const response = await api.request({
      url: `/images/getpreviewimage`,
      method: 'GET',
      params: {
        productId: productId,
        itemId: itemId
      },
    })

    return response.data.url
  },
  
  getOverlayText: async function(galleryName: string, imgId: string, userId: string, userName: string, luckyNumber: string| undefined) {
    const response = await api.request({
      url: `/images/${galleryName}/${imgId}/overlay-text/${userName}`,
      method: 'POST',
      data: {
        userId: userId,
        luckyNumber: luckyNumber
      },
    })

    return response
  },

  getPresignedUrl: async function(galleryName: string, imgId: string, userId: string, paymentId: string, userName: string):Promise<string> {
    const response = await api.request({
      url: `/images/result/${galleryName}/${imgId}/overlay-text/${userName}`,
      method: 'POST',
      data: {
        image: imgId,
        userId: userId,
        paymentId: paymentId
      },
    })

    return response.data.url
  },

  getWatermark: async function(galleryName: string, imageDirectory: string) {
    const response = await api.request({
      url: `/images/${galleryName}/${imageDirectory}/watermark`,
      method: 'GET',
    })

    return response.data
  },
}
