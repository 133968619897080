import {
  createContext,
  useContext,
  useReducer,
  Dispatch,
  PropsWithChildren
} from 'react'

import { UserActionTypes, UserActions } from '@/providers/user/action'
import { calculateZodiac } from '@/utils/customerHelper'
import { UserContext } from '@/providers/user/types'

const defaultUserContext: UserContext = {
  uid: '',
  firstName: '',
  lastName: '',
  birthDate: new Date(),
  zodiac: calculateZodiac(new Date()),
  galleryId: '',
  galleryName: '',
  itemId: '',
  isConsent: false,
  imageDirectory: ''
}

const UserContext = createContext<{
  context: UserContext
  dispatch: Dispatch<UserActions>
} | null>(null)

const reducer = (context: UserContext, { type, payload }: UserActions) => {
  switch (type) {
    case UserActionTypes.AddUserInfo:
      return { ...context, ...payload }
    default:
      throw new Error('invalid action')
  }
}

export const useUserContext = () => {
  const ctx = useContext(UserContext)

  if (!ctx) throw new Error('useUserContext can only be used in UserContext')

  return ctx
}

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [context, dispatch] = useReducer(reducer, defaultUserContext)

  return (
    <UserContext.Provider value={{ context, dispatch }}>
      {children}
    </UserContext.Provider>
  )
}
