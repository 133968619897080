import { API_URL, GBPAY_URL } from '@/config'
import axios, { AxiosError } from 'axios'

export const api = axios.create({
  baseURL: API_URL
})

export const gbpay_api = axios.create({
  baseURL: GBPAY_URL,
})

// defining a custom error handler for all APIs
const errorHandler = (error: AxiosError) => {
  const statusCode = error.response?.status

  if (error.code === AxiosError.ERR_CANCELED) {
    return Promise.resolve()
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error)
  }

  return Promise.reject(error)
}

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(
  response => response, 
  error => errorHandler(error)
)
