import { FunctionComponent, PropsWithChildren, FC } from 'react'

import { PaymentProvider }  from './payment'
import { UserProvider }  from './user'
import { GalleryProvider }  from './gallery'
import { PackageProvider }  from './package'

const combineComponents = (components: FC[]): FC<PropsWithChildren> => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent: FunctionComponent<PropsWithChildren>) => {
      return ({ children }: PropsWithChildren): JSX.Element => {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }: PropsWithChildren) => <>{children}</>,
  )
}

export const AppContextProvider = combineComponents([
  PaymentProvider,
  UserProvider,
  GalleryProvider,
  PackageProvider,
])
