import { Route, Navigate } from 'react-router-dom'
import { ApmRoutes } from '@elastic/apm-rum-react'

import { inAppRouteList } from '@/constants'
import ProductListPage from '@/pages/ProductListPage'
import ProductPage from '@/pages/ProductPage'
import ItemListPage from '@/pages/ItemListPage'
import PaymentPage from '@/pages/Payment'
import PaymentSuccessPage from '@/pages/PaymentSuccess'
import PaymentFailedPage from '@/pages/PaymentFailed'
import NotFoundPage from '@/pages/NotFoundPage'
import CartPage from '@/pages/CartPage'
import CustomerInfoPage from '@/pages/CustomerInfoPage'
import PreviewPage from '@/pages/PreviewPage'
import PaymentGatewayPage from '@/pages/PaymentGateway'
import DownloadPage from '@/pages/DownloadPage'

interface routeProps {
  uid: string
}
function AppRoutes({ uid }: routeProps) {
  return (
    <>
      <ApmRoutes>
        <Route index path={inAppRouteList.Root} element={<Navigate to={inAppRouteList.ProductList} />} />
        <Route path={inAppRouteList.ProductList} element={<ProductListPage uid={uid} />} />
        <Route path={inAppRouteList.ProductDetail} element={<ProductPage />} />
        <Route path={inAppRouteList.ItemList} element={<ItemListPage />} />
        <Route path={inAppRouteList.CustomerInfo} element={<CustomerInfoPage />} />
        <Route path={inAppRouteList.Cart} element={<CartPage />} />
        <Route path={inAppRouteList.Preview} element={<PreviewPage />} />
        <Route path={inAppRouteList.Payment} element={<PaymentPage />} />
        <Route path={inAppRouteList.PaymentGateway} element={<PaymentGatewayPage />} />
        <Route path={inAppRouteList.PaymentSuccess} element={<PaymentSuccessPage />} />
        <Route path={inAppRouteList.PaymentFailed} element={<PaymentFailedPage />} />
        <Route path={inAppRouteList.Fallback} element={<NotFoundPage />} />
        <Route path={inAppRouteList.Download} element={<DownloadPage />} />
      </ApmRoutes>
    </>
  )
}

export default AppRoutes
