import { NavLink } from 'react-router-dom'

import { Button } from '@/components/ui/button'

interface Props {
  msg: string
  cta: boolean
}

const NotFoundError = ({ msg, cta }: Props) => (
  <div className='flex flex-col items-center justify-center p-4'>
    <div className="p-4">ไม่พบข้อมูล {msg}</div>
    {
      cta &&
      <div className="p-4">
        <Button
          className="bg-dark-red hover:bg-dark-red py-4 px-10 rounded-full text-gold"
          asChild
        >
          <NavLink
            to="/products"
          >
            <div className="font-bold text-sm text-gold text-center">
              กลับสู่หน้าเลือกวิธีชำระเงิน
            </div>
          </NavLink>
        </Button>
      </div>
    }
  </div>
)

export default NotFoundError
