'use client'
import { useEffect, useState } from 'react'
import { Navigate, NavLink, useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import { useUserContext } from '@/providers'
import { getToItemListUrl } from '@/routes/helper'
import { inAppRouteList } from '@/constants'
import { ImageAPI } from '@/apis/imageApis'
import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import Spinner from '@/components/common/spinner'

const CartPage = () => {
  const navigate = useNavigate()
  const { context: userContext } = useUserContext()

  const [img, setImg] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  if (!userContext.firstName) return <Navigate to={inAppRouteList.ProductList} />

  const getImage = async () => {
    const data = await ImageAPI.getWatermark(userContext.galleryName, userContext.imageDirectory)
    setImg(data)
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    getImage()
  }, [])

  const goToPreview = () => {
    navigate('/preview')
  }

  if (isLoading) return <Spinner />

  return (
    <>
      <div className="flex my-4 items-center justify-center h-12">
        <p className="font-medium text-xl text-center">
          ตัวอย่างรูปภาพก่อนการสลักชื่อ
        </p>
      </div>
      <Card className="w-[350px] bg-neutral-100 py-6 shadow-xl">
        <CardContent className="grid">
          <div className="flex-1 flex flex-col gap-2">
            <div className="flex gap-2">
              <div className="flex-1 relative w-full">
                <div className="flex flex-col justify-center items-center gap-2">
                  {
                    img && 
                    <img
                      src={`data:image/png;base64,${img}`}
                      alt='wallpaper'
                      crossOrigin='anonymous'
                      className='pointer-events-none max-h-[300px]'
                    />
                  }
                </div>
              </div>
            </div>
            <div className="w-full flex justify-center text-sm text-gray-400 text-center mt-4">
              <div className="flex flex-col justify-center items-center gap-2">
                แก้ไข
              </div>
              <div>
                <NavLink
                  className="p-4 font-bold"
                  to={getToItemListUrl(userContext.galleryId)}
                >
                  <i className="fa fa-pencil"></i>
                </NavLink>
              </div>
            </div>
            <div className="w-full text-sm flex flex-row justify-center">
              <div className="text-right mr-2">
                <div>ชื่อ:</div>
                <div>วันเกิด:</div>
                <div>ราศี:</div>
              </div>
              <div>
                <div>{ userContext.firstName } { userContext.lastName }</div>
                <div>{format(userContext.birthDate, 'dd-MM-yyyy')}</div>
                <div>{userContext.zodiac}</div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="fixed bottom-0 rounded-t-2xl h-[80px] flex flex-col justify-center w-full bg-white shadow-next-button">
        <div className="m-2">
          <Button
            className="bg-dark-red hover:bg-dark-red min-w-[200px] w-full rounded-md font-bold text-sm text-gold"
            onClick={goToPreview}
          >
            ดูตัวอย่างภาพพร้อมสลักชื่อ
          </Button>
        </div>
      </div>
    </>
  )
}

export default CartPage
