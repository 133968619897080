import { createSearchParams, useNavigate } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'

import { PaymentAPI } from '@/apis/paymentAPI'
import { Button, Card, CardContent, Input, Label } from '@/components/ui'
import { CardDetail, PaymentStep } from '@/providers/payment/types'
import { usePaymentContext } from '@/providers'

interface TokenProps {
  token: string;
  cardDetail: CardDetail;
  handleCardDetail: (detail: CardDetail) => void;
  handleLoading: (value: boolean) => void;
  handleStep: (value: PaymentStep) => void;
  handleToken: (value: string) => void;
}

const setInputBorderColor = (e: React.FocusEvent<HTMLInputElement, Element>) => {
  const target = e.currentTarget as HTMLInputElement;
  if (target && target.value) {
    target.style.borderColor = 'rgb(226, 232, 240)'
  }
}

const CreditCardInput = ({ token, cardDetail, handleCardDetail, handleLoading, handleStep, handleToken }: TokenProps) => {
  const navigate = useNavigate()
  const cardNumberRef = useRef<HTMLInputElement>(null)
  const expirationMonthRef = useRef<HTMLInputElement>(null)
  const expirationYearRef = useRef<HTMLInputElement>(null)
  const securityCodeRef = useRef<HTMLInputElement>(null)
  const nameRef = useRef<HTMLInputElement>(null)

  const { context: paymentContext } = usePaymentContext()

  useEffect(() => {
    if (!token && cardDetail.number) getToken()
  }, [cardDetail])

  const validateCreditCard = () => {
    // advance input validation https://ui.shadcn.com/docs/components/form
    // basic input validation sample
    if (!cardNumberRef.current) return
    if (!cardNumberRef.current.value) {
      cardNumberRef.current.style.borderColor = 'red'
      return
    }

    if (!expirationMonthRef.current) return
    if (!expirationMonthRef.current.value) {
      expirationMonthRef.current.style.borderColor = 'red'
      return
    }

    if (!expirationYearRef.current) return
    if (!expirationYearRef.current.value) {
      expirationYearRef.current.style.borderColor = 'red'
      return
    }

    if (!securityCodeRef.current) return
    if (!securityCodeRef.current.value) {
      securityCodeRef.current.style.borderColor = 'red'
      return
    }

    if (!nameRef.current) return
    if (!nameRef.current.value) {
      nameRef.current.style.borderColor = 'red'
      return
    }

    handleCardDetail({
      customerName: nameRef.current.value,
      number: cardNumberRef.current.value,
      expirationMonth: expirationMonthRef.current.value,
      expirationYear: expirationYearRef.current.value,
      securityCode: securityCodeRef.current.value
    })
  }

  const getToken = async () => {
    handleLoading(true)
  
    cardDetail.number
    const data = {
      rememberCard: false,
      card: cardDetail
    }
    const PAYMENT_SUCCESS_STATUS = '00'

    const { resultCode, card } = await PaymentAPI.getToken(data, paymentContext.public_key)
    if (resultCode == PAYMENT_SUCCESS_STATUS){
      handleToken(card.token)
        handleStep('credit-charge')
        handleLoading(false)
    } else {
      navigate({
        pathname: '/payment',
        search: createSearchParams({
          type: paymentContext.type,
          code: resultCode
        }).toString()
      })
    }
  }

  return (
    <>
      <div className="flex items-center justify-center h-16">
        <p className="font-medium text-xl text-center">ข้อมูลบัตรเครดิต</p>
      </div>

      <Card className='w-[420px] bg-neutral-100 py-6 shadow-xl'>
        <CardContent className='grid gap-4'>
          <div className='grid w-full max-w-sm items-center gap-1.5'>
            <Label htmlFor='cardNumber' className='text-sm'>Card Number</Label>
            <Input
              id='cardNumber'
              className='bg-white'
              type='text'
              maxLength={16}
              ref={cardNumberRef}
              onBlur={setInputBorderColor}
            />
          </div>
          <div className='grid grid-cols-3 gap-4'>
            <div className='w-full max-w-sm items-center gap-1.5'>
              <Label htmlFor='expirationMonth' className='text-sm'>MM</Label>
              <Input
                id='expirationMonth'
                className='bg-white'
                type='text'
                maxLength={2}
                ref={expirationMonthRef}
                onBlur={setInputBorderColor}
              />
            </div>
            <div className='w-full max-w-sm items-center gap-1.5'>
              <Label htmlFor='expirationYear' className='text-sm'>YY</Label>
              <Input
                id='expirationYear'
                className='bg-white'
                type='text'
                maxLength={2}
                ref={expirationYearRef}
                onBlur={setInputBorderColor}
              />
            </div>
            <div className='w-full max-w-sm items-center gap-1.5'>
              <Label htmlFor='securityCode' className='text-sm'>CVV</Label>
              <Input
                id='securityCode'
                className='bg-white'
                type='text'
                maxLength={3}
                ref={securityCodeRef}
                onBlur={setInputBorderColor}
              />
            </div>
          </div>
          <div className='grid w-full max-w-sm items-center gap-1.5'>
            <Label htmlFor='name' className='text-sm'>Credit card holder name</Label>
            <Input
              id='name'
              className='bg-white'
              type='text'
              ref={nameRef}
            />
          </div>
        </CardContent>
      </Card>
      <div className="fixed bottom-0 rounded-t-2xl h-[80px] flex flex-col justify-center w-full bg-white shadow-next-button">
        <div className="m-2">
          <Button
            className="bg-dark-red hover:bg-dark-red min-w-[200px] w-full rounded-md font-bold text-sm text-gold"
            onClick={validateCreditCard}
          >
            ต่อไป
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreditCardInput
