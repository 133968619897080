import { NavLink } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import line from 'src/assets/social/line.png'

interface Props {
  msg: string
  userID: string
  cta: boolean
}

const PaymentError = ({ msg, userID, cta }: Props) => (
  <div className="flex flex-col items-center justify-center p-4">
    <div className="p-4">{msg}</div>
    <div>
      <a className="flex flex-row items-center" href="https://lin.ee/WYKUvrp">
        <img src={line} style={{ height: '45px' }} />
      </a>
    </div>
    <div className="p-4">กรุณาติดต่อไปยัง OA เพื่อให้ admin support</div>

    <div className="p-4">รหัสการซื้อของคุณ: {userID}</div>
    {cta && (
      <div className="p-4">
        <Button
          variant="destructive"
          className="bg-dark-red hover:bg-dark-red py-4 px-10 rounded-full text-gold"
          asChild
        >
          <NavLink to="/payment">
            <div className="font-bold text-sm text-gold text-center">
              กลับสู่หน้าเลือกวิธีชำระเงิน
            </div>
          </NavLink>
        </Button>
      </div>
    )}
  </div>
)

export default PaymentError
