
/** route path parameters */
export enum InAppRoutePathParams {
  ProductId = 'ProductId',
  ItemId = 'ItemId'
}

type InAppRoutePathParamList = {
  [key in InAppRoutePathParams]: string
}

export const inAppRoutePathParamList: InAppRoutePathParamList = {
  [InAppRoutePathParams.ProductId]: ':productId',
  [InAppRoutePathParams.ItemId]: ':itemId',
}

/** routes */
export enum InAppRoutes {
  Root = 'Root',
  Fallback = 'Fallback',
  ProductList = 'ProductList',
  ProductDetail = 'ProductDetail',
  ItemList = 'ItemList',
  CustomerInfo = 'CustomerInfo',
  Cart = 'Cart',
  Preview = 'Preview',
  Payment = 'Payment',
  PaymentGateway = 'PaymentGateway',
  PaymentSuccess = 'PaymentSuccess',
  PaymentFailed = 'PaymentFailed',
  Download = 'Download'
}

type InAppRouteList = {
  [key in InAppRoutes]: string
}

const {ProductId, ItemId} = inAppRoutePathParamList

// ItemDetail is the most complex page
export const inAppRouteList: InAppRouteList = {
  [InAppRoutes.Root]: '/',
  [InAppRoutes.Fallback]: '*',
  [InAppRoutes.ProductList]: '/products',
  [InAppRoutes.ProductDetail]: `/products/${ProductId}`,
  [InAppRoutes.ItemList]: `/products/${ProductId}/items`,
  [InAppRoutes.CustomerInfo]: `/customer`,
  [InAppRoutes.Cart]: `/cart`,
  [InAppRoutes.Preview]: `/preview`,
  [InAppRoutes.Payment]: '/payment',
  [InAppRoutes.PaymentGateway]: '/payment-gateway',
  [InAppRoutes.PaymentSuccess]: '/payment-successful',
  [InAppRoutes.PaymentFailed]: '/payment-failed',
  [InAppRoutes.Download]: '/download',
}

