import { useEffect } from 'react'
import { NavLink, useParams, Navigate } from 'react-router-dom'

import { inAppRouteList } from '@/constants'
import { Button } from '@/components/ui/button'
import { PackageActionTypes, usePackageContext } from '@/providers/package'
import { GalleryActionTypes, useGalleryContext } from '@/providers/gallery'
import { PackageAPI } from '@/apis/packageAPI'
import { GalleryAPI } from '@/apis/galleryAPI'
import { ItemDetail } from '@/providers/package/types'
import Circle from '@/components/svg-icon/circle'


function ItemListPage() {
  const { productId } = useParams()

  if (!productId) return <Navigate to={inAppRouteList.ProductList} />

  const { context: galleryContext, dispatch: galleryDispatch } = useGalleryContext()
  const { context: packageContext, dispatch: packageDispatch } = usePackageContext()

  const initPackageContext = async () => {
    const packageData = await PackageAPI.getById(productId)

    packageDispatch({
      type: PackageActionTypes.Select,
      payload: [packageData]
    })
  }

  const initGalleryContext = async () => {
    try {
      const data = await GalleryAPI.fetchGalleryByID(productId)
      
      galleryDispatch({
        type: GalleryActionTypes.ShowDetail,
        payload: [data]
      })
    } catch (e) {
      return <Navigate to={inAppRouteList.ProductList} />
    }
  }

  useEffect(() => {
    const data = galleryContext.find(g => g._id === productId)

    if (!(data && data.title)) {
      // when galleryContext is invalid then we have to fetch gallery by id
      initGalleryContext()
    }

    initPackageContext()
  }, [])

  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center p-2">
        {
          <div className="flex items-center h-12">
            <p className="font-medium text-xl text-center">
              { packageContext.length > 0 && packageContext[0].title }
            </p>
          </div>
        }
        {
          packageContext.map((packageItems) => {
            return (
              packageItems.type === PackageActionTypes.Select &&
              packageItems.items.map((item: ItemDetail, itemIndex: number) => {
                return (
                  <Button
                    className="w-full sm:max-w-[380px] h-auto m-2 rounded-xl outline-double font-white bg-dark-red hover:bg-dark-red"
                    key={itemIndex}
                    asChild
                  >
                    <NavLink
                      className="flex flex-row !justify-start items-center w-full gap-x-2"
                      to={inAppRouteList.CustomerInfo}
                      state={{ productId: productId, itemId: itemIndex.toString() }}
                    >
                      <Circle fill={item.color} stroke="black" width="3rem" height="3rem" />
                      <span className="w-auto py-[1rem] text-sm tracking-widest">
                        <p className="mr-[1rem]">{item.name}</p>
                      </span>
                    </NavLink>
                  </Button>
                )
              })
            )
          }
        )}
      </div>
    </>
  )
}

export default ItemListPage
