
import { createContext, useReducer } from 'react'

import { ModalBackdrop, CustomModal } from '@/components'
import { ModalState } from './types'
import { ModalActionTypes, ModalActions } from './action'

const modalDefaultState: ModalState = {
  show: false,
  modalProps: null
}

const reducer = (state: ModalState, { type, payload }: ModalActions) => {
  switch (type) {
    case ModalActionTypes.Show:
      return { ...state, show: true, modalProps: payload }
    case ModalActionTypes.Hide:
      return { ...modalDefaultState }
    default:
      throw new Error('invalid modal action')
  }
}

export const ModalContext = createContext<{
  state: ModalState
  dispatch: React.Dispatch<ModalActions>
} | null>(null)

export const ModalProvider: React.FC<React.PropsWithChildren> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, modalDefaultState)

  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {state.show && (
        <ModalBackdrop>
          <CustomModal {...state.modalProps} />
        </ModalBackdrop>
      )}
      {children}
    </ModalContext.Provider>
  )
}
