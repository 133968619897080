import { GalleryContext } from '@/providers/gallery/types'
import { api } from './configs/axiosConfigs'

export const GalleryAPI = {
  getAll: async function (): Promise<GalleryContext[]> {
    const response = await api.request({
      url: '/galleries/',
      method: 'GET'
    })

    return response.data
  },

  fetchGalleryByID: async function (id: string): Promise<GalleryContext> {
    const response = await api.request({
      url: `/galleries/${id}`,
      method: 'GET'
    })

    return response.data
  }
}
