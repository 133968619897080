import logo from 'src/assets/images/rubsub-logo.png'
import { Helmet } from 'react-helmet'
import { BASE_URL } from '@/config'

const headerContainer = `
  w-full
  h-12
  flex
  flex-col
  justify-center
  content-center
  sticky
  top-0 
  z-50
  shadow-2xl
  bg-dark-red
`

const headerTitle = `
  text-xl
  font-black
  decoration-black
  text-center
  items-center
  self-center
  cursor-pointer
`

const logoClass = `
  h-12
  bg-cover
`

const Header = () => {
  const gotoHomepage = () => {
    window.location.href = '/'
  }

  return (
    <>
      {/* <Helmet>
        <title>รับทรัพย์ 789 - วอลเปเปอร์สายมู เสริมมงคลของคุณตลอดปี</title>
        <meta name="title" content="รับทรัพย์ 789 - วอลเปเปอร์สายมู เสริมมงคลของคุณตลอดปี" />
        <meta name="description" content="วอลเปเปอร์สายมู เสริมดวงตามวัน ราศีเกิด คนโสดได้คู่ คนมีคู่เสริมพลังรัก มีโชค มีลาภ การงานไม่ติดขัด การเงินสะพัดชัดเจน ไม่มีติดขัด เสริมมงคลของคุณ ตลอดปี" />
        <meta name="keywords" content="วอลเปเปอร์,สายมู,ความรัก,คนโสด,คู่รัก,การเงิน,โชคลาภ" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="รับทรัพย์ 789 - วอลเปเปอร์สายมู เสริมมงคลของคุณตลอดปี" />
        <meta property="og:url" content={BASE_URL} />
        <meta property="og:description" content="วอลเปเปอร์สายมู เสริมดวงตามวัน ราศีเกิด คนโสดได้คู่ คนมีคู่เสริมพลังรัก มีโชค มีลาภ การงานไม่ติดขัด การเงินสะพัดชัดเจน ไม่มีติดขัด เสริมมงคลของคุณ ตลอดปี" />
        <meta property="og:image" content={BASE_URL + "/og-789.jpeg"} />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="th_TH" />
      </Helmet> */}
      <div className={headerContainer}>
        <div className={headerTitle} onClick={gotoHomepage}><img  src={logo} className={logoClass} alt="logo"/></div>
      </div>
    </>
  )
}

export default Header