import { api } from '@/apis/configs/axiosConfigs'

export const UserAPI = {
  createUser: async function (data: object) {
    const response = await api.request({
      url: `/users/`,
      method: 'POST',
      data,
    })

    return response.data
  }
}
