import { PaymentAPI } from '@/apis/paymentAPI'
import { Button, Card, CardContent } from '@/components/ui'
import { API_URL, BASE_URL } from '@/config'
import { usePaymentContext } from '@/providers'
import { CardDetail, PaymentStep } from '@/providers/payment/types'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

interface ChargeProps {
  token: string
  cardDetail: CardDetail;
  handleLoading: (value: boolean) => void;
  handleStep: (value: PaymentStep) => void;
  handleGbpReferenceNo: (value: string) => void;
}

const Charge = ({ token, cardDetail, handleLoading, handleStep, handleGbpReferenceNo }: ChargeProps) => {
  const navigate = useNavigate()

  const { context: paymentContext } = usePaymentContext()

  const getCharge = async () => {
    handleLoading(true)

    const data = {
      amount: paymentContext.amount.toFixed(2),
      referenceNo: paymentContext.referenceNo,
      card: {
        token: token
      },
      customerName: cardDetail.customerName,
      otp: 'Y',
      responseUrl: `${API_URL}/payments/receive`,
      backgroundUrl: `${BASE_URL}/payment-gateway`
    }

    const response = await PaymentAPI.getCharge(data, paymentContext.secret_key)

    handleGbpReferenceNo(response.gbpReferenceNo)
    handleStep('credit-3d-secure')
    handleLoading(false)
  }

  return (
    <>
      <Card className='w-[420px] bg-neutral-100 py-6 shadow-xl'>
        <CardContent className='grid gap-4'>
          <div className='flex grid grid-rows-4 grid-cols-2 justify-around items-center w-full text-sm'>
            <div>Merchant:</div>
            <div>RAPSAP 789 COMPANY LIMITED</div>
            <div>Amount:</div>
            <div>THB {paymentContext.amount.toFixed(2)}</div>
            <div>Date:</div>
            <div>{format(new Date(), 'dd-MM-yyyy')}</div>
            <div>Card Number:</div>
            <div>{cardDetail.number}</div>
          </div>
        </CardContent>
      </Card>
      <div className="fixed bottom-0 rounded-t-2xl flex flex-col justify-center w-full bg-white shadow-next-button content-center">
        <div className="m-2 mt-3">
          <Button
            className="bg-dark-red hover:bg-dark-red min-w-[90px] w-full rounded-md font-bold text-sm text-gold"
            onClick={getCharge}
          >
            จ่าย
          </Button>
        </div>
        <div className="m-2 mb-3">
          <Button
            className="bg-white-600 text-black min-w-[90px] w-full border hover:bg-white-600 hover:border-red-300 rounded-md font-bold text-sm"
            onClick={() => navigate('/payment')}
          >
            ยกเลิก
          </Button>
        </div>
      </div>
    </>
  )
}

export default Charge
