import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import Spinner from '@/components/common/spinner'
import { getProductDetailUrl } from '@/routes/helper'
import { Button } from '@/components/ui/button'
import { GalleryActionTypes, useGalleryContext } from '@/providers/gallery'
import { GalleryAPI } from '@/apis/galleryAPI'
import { UserActionTypes, useUserContext } from '@/providers'

import facebook from 'src/assets/social/facebook.png'
import tiktok from 'src/assets/social/tiktok.png'
import instagram from 'src/assets/social/instagram.png'
import line from 'src/assets/social/line.png'

interface productListProps {
  uid: string
}

const ProductListPage = ({ uid }: productListProps) => {  
  const [isLoading, setIsLoading] = useState(false)
  const [errMsg, setErrMsg] = useState('')
  
  const { context: galleryContext, dispatch: galleryDispatch } = useGalleryContext()
  const { context: userContext, dispatch: userDispatch } = useUserContext()

  const fetchData = async () => {
    try {
      setIsLoading(true)
  
      const data = await GalleryAPI.getAll()
  
      galleryDispatch({
        type: GalleryActionTypes.ShowDetail,
        payload: data
      })

      setIsLoading(false)
    } catch (e) {
      if (e instanceof Error) {
        setErrMsg(e.toString())
      }
      
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const data = {
      ...userContext,
      ...{ uid }
    }

    userDispatch({
      type: UserActionTypes.AddUserInfo,
      payload: data
    })

    fetchData()
  }, [])

  /** UI rendering */

  if (isLoading) return <Spinner />

  if (errMsg && galleryContext.length === 0) return <div className='text-center font-bold'>ขออภัย ระบบไม่พร้อมให้บริการในขณะนี้ { errMsg }</div>

  return (
    <>
      <div className="font-['Prompt']">
        {
          galleryContext.map((gallery, index) => {
            return (
              // size of single bg should be 100vh to fit a mobile screen
              // class "absolute left-1/2 -translate-x-1/2 justify-center" will make page overflow
              <section
                key={index}
                style={{
                  backgroundImage: `url(${gallery.galleryImage})`
                }}
                className="bg-cover w-[100vw] h-[calc(100vh-48px)] bg-fix relative bg-no-repeat bg-center"
              >
                <div className="flex h-[30vh] flex-col justify-between text-center justify-items-center ">
                  {
                    gallery.status && (
                      <div className="m-2 font-bold text-base md:text-3xl text-[#D52B2C]">
                        {gallery.status}
                      </div>
                    )
                  }
                  {gallery.imageInformation.length != 0 && 
                  <div>
                    <Button
                      className="font-black py-4 px-10 rounded-full bg-dark-red hover:bg-dark-red"
                      id={`btn-buy-${gallery.galleryName}`}
                      asChild
                    >
                      <NavLink
                        to={getProductDetailUrl(gallery._id)}
                      >
                        <div className="font-bold text-sm text-gold">
                          ซื้อเลย
                        </div>
                      </NavLink>
                    </Button>
                  </div>}
                  
                </div>
              </section>
            )
          })
        }
        <div className="flex flex-col items-center shadow-2xl">
          <div className="w-full p-3 text-sm font-bold text-center">ห้ามพลาดช่องทางรวย ช่องทางแห่งศรัทธา</div>
          <div>
            <div className="flex flex-row justify-between w-[150px] m-4">
              <div>
                <a 
                  className="flex flex-row items-center" 
                  href="https://www.facebook.com/rapsap789.official"
                >
                  <img src={facebook} style={{ height: '28px', margin: '1px' }} />
                </a>
              </div>
              <div>
                <a 
                  className="flex flex-row items-center" 
                  href="https://www.instagram.com/rapsap789.official"
                >
                  <img src={instagram} style={{ height: '30px' }} />
                </a>
              </div>
              <div>
                <a 
                  className="flex flex-row items-center" 
                  href="https://www.tiktok.com/@rapsap789.official"
                >
                  <img src={tiktok} style={{ height: '30px' }} />
                </a>
              </div>
              <div>
                <a 
                  className="flex flex-row items-center" 
                  href="https://lin.ee/WYKUvrp"
                >
                  <img src={line} style={{ height: '30px' }} />
                </a>
              </div>
            </div>
          </div>
          <hr className="w-full h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100"/>
          <div className="w-full text-xs text-center p-4">
            © Copyright 2023. Rapsap789 All Right Reserved.
          </div>
        </div>
      </div>
    </>
  )
}

export default ProductListPage
