import { InfinitySpin } from 'react-loader-spinner'

const Spinner = () => (
  <>
    <div className='flex justify-around items-center w-full h-screen'>
      <InfinitySpin
        width='200'
        color='#4fa94d'
      />
    </div>
  </>
)

export default Spinner
