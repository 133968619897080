import { Card, CardContent } from '@/components/ui'
import { useEffect } from 'react'
import { usePaymentContext } from '@/providers'
import { GBPAY_URL } from '@/config';


interface ThreeDSecureProps {
  gbpReferenceNo: string;
}

const ThreeDSecure = ({ gbpReferenceNo }: ThreeDSecureProps) => {
  const { context: paymentContext } = usePaymentContext()

  useEffect(() => {
    const form = document.getElementById('3dSecureForm')
    if (form) {
      // @ts-ignore
      form.submit()
    }
  }, [])

  return (
    <>
      <Card className='w-[420px] bg-neutral-100 py-6 shadow-xl'>
        <CardContent className='grid gap-4'>
          <form name="form" id='3dSecureForm' action={GBPAY_URL + '/v2/tokens/3d_secured'} method="POST">
            กรุณารอซักครู่ ...
            <input type="hidden" name="publicKey" id='publicKey' value={paymentContext.public_key} />
            <input type="hidden" name="gbpReferenceNo" id='gbpReferenceNo' value={gbpReferenceNo} />
          </form>
        </CardContent>
      </Card>
    </>
  )
}

export default ThreeDSecure
