import { PropsWithChildren, useEffect, useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import liff from '@line/liff'
import TagManager from 'react-gtm-module'
import VConsole from 'vconsole'
import { v4 as uuidV4 } from 'uuid'
import { init as initApm } from '@elastic/apm-rum'

import AppRoutes from '@/routes'
import Header from '@/components/header'
import Spinner from '@/components/common/spinner'
import NotFoundError from '@/components/common/notFoundError'
import { AppContextProvider } from '@/providers'
import { UserAPI } from '@/apis/userAPI'
import { 
  LIFF_ID,
  APP_ENV,
  GTM_ID,
  APM_APPLICATION_NAME,
  APM_SERVER_URL
} from '@/config'
import { getUserInfo } from '@/utils/localStorage'

type UserType = 'anonymous' | 'line'

interface Line {
  displayName?: string
  statusMessage?: string
  pictureUrl?: string
}

interface User {
  userId: string
  type: UserType
  line?: Line
}

if (APP_ENV === 'prod') {
  /** init GTM for prod env */
  const tagManagerArgs = {
    gtmId: GTM_ID
  }
  
  TagManager.initialize(tagManagerArgs)
} else {
  /** init VConsole for dev env */
  new VConsole({ theme: 'dark' })
}

const AppContainer = ({ children }: PropsWithChildren) => <div>{children}</div>

/** Routers will be handled from this function */
const App = () => {
  const [uid, setUID] = useState<string>('');
  const [isLoading, setIsLoading] = useState(true)
  const [errMsg, setErrMsg] = useState('')

  initApm({
    serviceName: APM_APPLICATION_NAME,
    serverUrl: APM_SERVER_URL,
    serviceVersion: '',
    environment: APP_ENV
  })

  const authUser = async (user: User) => {
    const existingUser = getUserInfo()
    if (existingUser) {
      try {
        const { uid } = JSON.parse(existingUser)
        if (uid) {
          setUID(uid)
          return
        }
      } catch (e) {
        if (e instanceof Error) {
          setErrMsg(e.message)
        }
      }
    }

    const newUser = await UserAPI.createUser(user)
    if (newUser.userId) {
      setUID(newUser.userId)
    } 
  }

  const init = async () => {
    await authUser({
      userId: uuidV4(),
      type: 'anonymous',
    })
  }

  const liffInit = async () => {
    try {
      setIsLoading(true)

      await liff.init({ liffId: LIFF_ID })

      if (liff.isLoggedIn() && liff.isInClient()) {
        const profile = await liff.getProfile()

        const { userId, ...line } = profile

        if (profile.userId) {
          await authUser({
            userId,
            type: 'line',
            line,
          })
        }
      } else {
        await init()
      }
    } catch (e) {
      if (e instanceof Error) {
        setErrMsg(e.message)
      }
    } finally {
      setIsLoading(false)
    }
  };

  useEffect(() => {
    if (window.location.pathname === '/download'){
      setIsLoading(false)
    }

    liffInit()
  }, [])

  /** UI rendering */

  if (isLoading) return (
    <div>
      <Header />
      <Spinner />
    </div>
  )

  if (errMsg) return (
    <div>
      <Header />
      <div className='text-center font-bold'>{ errMsg }</div>
    </div>
  )

  if (!uid && window.location.pathname !== '/download') return (
    <div>
      <Header />
      <NotFoundError msg="" cta={false} />
    </div>
  )

  return (
    <BrowserRouter>
      <Header />
      <AppContextProvider>
        <AppContainer>
          <AppRoutes uid={uid} />
        </AppContainer>
      </AppContextProvider>
    </BrowserRouter>
  )
}

export default App
