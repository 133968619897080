import { Buffer } from 'buffer';
import { api, gbpay_api } from '@/apis/configs/axiosConfigs'
import { GBPayKeys } from '@/providers/payment/types'

export const PaymentAPI = {
  getGBPayKeys: async function (): Promise<GBPayKeys> {
    const response = await api.request({
      url: '/payments/keys',
      method: 'GET'
    })

    return response.data
  },

  getQRCode: async function (data: object, publicKey: string) {
    const response = await gbpay_api.request({
      url: `/v3/qrcode`,
      method: 'POST',
      data: data,
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + btoa(publicKey + ':')
      },
      responseType: 'arraybuffer'
    })

    const imageBuffer = Buffer.from(response.data, 'binary')
    const base64Image = imageBuffer.toString('base64')
    const dataUrl = `data:image/png;base64,${base64Image}`

    return dataUrl
  },

  getQRStatus: async function (data: object, secretKey: string) {
    const response = await gbpay_api.request({
      url: `/v1/check_status_txn`,
      method: 'POST',
      data: data,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Basic ${btoa(secretKey + ':')}`
      },
    })

    return response.data
  },

  getToken: async function (data: object, publicKey: string) {
    const response = await gbpay_api.request({
      url: `/v2/tokens`,
      method: 'POST',
      data: data,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Basic ${btoa(publicKey + ':')}`
      }
    })

    return response.data
  },

  getCharge: async function (data: object, secretKey: string) {
    const response = await gbpay_api.request({
      url: `/v2/tokens/charge`,
      method: 'POST',
      data: data,
      headers: {
        'content-type': 'application/json',
        'Authorization': `Basic ${btoa(secretKey + ':')}`
      }
    })
    
    return response.data
  }
}
