import { 
  createContext, 
  useContext, 
  useReducer, 
  Dispatch, 
  PropsWithChildren 
} from 'react'

import { GalleryActionTypes, GalleryActions } from './action'
import { GalleryContext } from '@/providers/gallery/types'

const defaultGalleryContext: GalleryContext[] = []

const GalleryContext = createContext<{
  context: GalleryContext[]
  dispatch: Dispatch<GalleryActions>
} | null>(null)

const reducer = (_: GalleryContext[], { type, payload }: GalleryActions) => {
  switch (type) {
    case GalleryActionTypes.ShowDetail:
      return payload
    default:
      throw new Error('invalid action')
  }
}

export const useGalleryContext = () => {
  const ctx = useContext(GalleryContext)

  if (!ctx) throw new Error('useGalleryContext can only be used in GalleryContext')

  return ctx
}

export const GalleryProvider = ({ children }: PropsWithChildren) => {
  const [context, dispatch] = useReducer(reducer, defaultGalleryContext)

  return (
    <GalleryContext.Provider value={{ context, dispatch }}>
      {children}
    </GalleryContext.Provider>
  )
}
