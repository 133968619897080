import { Navigate, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import {
  Card,
  CardContent,
  RadioGroup,
  RadioGroupItem,
  Button,
  Label,
  Checkbox,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  ScrollArea
} from '@/components/ui/'
import {
  PaymentActionTypes,
  UserActionTypes,
  usePaymentContext,
  useUserContext
} from '@/providers'
import { PaymentType } from '@/providers/payment/types'
import { inAppRouteList } from '@/constants'
import { getUserInfo, setPaymentId } from '@/utils/localStorage'
import { TransactionAPI } from '@/apis/transactionAPI'
import { PaymentAPI } from '@/apis/paymentAPI'
import PaymentError from '@/components/common/paymentError'

const ServerError = () => (
  <div className="text-dark-red-700 p-6">
    ขออภัยระบบขัดข้อง กรุณาทำรายการใหม่อีกครั้ง
  </div>
)

const PaymentPage = () => {
  const navigate = useNavigate()

  const [consent, setConsent] = useState(false)
  const [payment, setPayment] = useState<PaymentType>('qr')
  const [errMsg, setErrMsg] = useState('')

  const { context: paymentContext, dispatch: paymentDispatch } =
    usePaymentContext()
  const { context: userContext, dispatch: userDispatch } = useUserContext()

  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  const updateTransaction = async (query: object, updateData: object) => {
    const data = await TransactionAPI.updateTransaction(
      paymentContext.id,
      query,
      updateData
    )
    return data
  }

  const initPayment = async () => {
    try {
      const GBPayKeys = await PaymentAPI.getGBPayKeys()

      paymentDispatch({
        type: PaymentActionTypes.SetGBPayKeys,
        payload: {
          ...paymentContext,
          ...GBPayKeys
        }
      })

      if (params.ref && params.type == 'credit') {
        paymentDispatch({
          type: PaymentActionTypes.SubmitPayment,
          payload: {
            ...paymentContext,
            ...{
              id: params.id,
              status: 'FAILURE',
              resultCode: params.code,
              updatedAt: new Date(),
              referenceNo: params.ref,
              amount: parseInt(params.amount),
              type: params.type as PaymentType
            }
          }
        })
      }
      // get userInfo from localStorage
      const userData = getUserInfo()
      if (!userData) return

      userDispatch({
        type: UserActionTypes.AddUserInfo,
        payload: { ...JSON.parse(userData) }
      })
    } catch (e) {
      if (e instanceof Error) {
        setErrMsg(e.message)
      }
    }
  }

  const goToPaymentMethod = async () => {
    const query = {
      user_id: userContext.uid,
      payment_id: paymentContext.id
    }

    const updateData = {
      payment_type: payment
    }

    setPaymentId(paymentContext.id)

    const data = await updateTransaction(query, updateData)

    paymentDispatch({
      type: PaymentActionTypes.SubmitPayment,
      payload: {
        ...paymentContext,
        type: payment,
        referenceNo: data.reference_no
      }
    })

    userDispatch({
      type: UserActionTypes.AddUserInfo,
      payload: {
        ...userContext,
        isConsent: true
      }
    })

    navigate('/payment-gateway')
  }

  useEffect(() => {
    initPayment()
  }, [])

  if (!params && !paymentContext.id)
    return <Navigate to={inAppRouteList.ProductList} />

  const PAYMENT_SUCCESS_STATUS = '00'
  const ERROR_MAPPING: { [key: string]: string } = {
    '01': 'Give cardholder contacts issuer bank',
    '02': 'การชำระเงินไม่สำเร็จ กรอกข้อมูลไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง',
    '05': 'การชำระเงินไม่สำเร็จ กรอกข้อมูลวันที่หมดอายุ, เลข CVV หรือหมายเลขบัตรเครดิตไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง',
    '51': 'การชำระเงินไม่สำเร็จ วงเงินไม่เพียงพอ โปรดติดต่อธนาคารผู้ออกบัตร และทำรายการใหม่อีกครั้ง',
    '54': 'การชำระเงินไม่สำเร็จ บัตรหมดอายุ กรุณาทำรายการใหม่อีกครั้ง',
    '55': 'การชำระเงินไม่สำเร็จ ป้อน Pin ไม่ถูกต้อง กรุณาทำรายการใหม่อีกครั้ง',
    '58': 'การชำระเงินไม่สำเร็จ ผู้ออกบัตรไม่อนุญาตให้ชำระด้วยบัตรเดบิต (Visa Electron, Mastercard Electron) กรุณาทำรายการใหม่อีกครั้ง',
    '91': 'การชำระเงินไม่สำเร็จ ผู้ออกบัตรไม่สามารถอนุมัติการชำระเงินได้ กรุณาทำรายการใหม่อีกครั้ง'
  }
  if (params && params.code) {
    const error = ERROR_MAPPING[params.code]
    if (error)
      return <PaymentError msg={error} userID={userContext.uid} cta={true} />
    if (params.code !== PAYMENT_SUCCESS_STATUS)
      return (
        <PaymentError
          msg="การชำระเงินไม่สำเร็จ กรุณาทำรายการใหม่อีกครั้ง"
          userID={userContext.uid}
          cta={true}
        />
      )
  }

  if (errMsg) return <ServerError />

  return (
    <>
      <div className="w-full h-full flex flex-col items-center justify-center">
        <div className="flex my-4 items-center justify-center h-12">
          <p className="font-medium text-xl text-center">วิธีการชำระเงิน</p>
        </div>
        <Card className="max-w-[420px] bg-neutral-100 py-6 shadow-xl mb-[12vh]">
          <CardContent className="grid gap-4">
            <div className="grid w-full max-w-sm items-center gap-1.5 text-sm">
              <RadioGroup
                defaultValue={payment}
                onValueChange={(v: PaymentType) => {
                  return setPayment(v)
                }}
              >
                <div className="flex flex-col space-x-2">
                  <div className="space-x-2">
                    <RadioGroupItem value="credit" id="r1" />
                    <Label htmlFor="r1" className="text-base font-bold">
                      ชำระเงินด้วยบัตรเครดิต
                    </Label>
                  </div>
                  <div className="flex space-x-2 my-3">
                    <img
                      src="https://dev.rapsap789.com/public/logo_asset/visa_logo.png"
                      alt="VISA"
                      className="mx-1 rounded-lg max-w-7 max-h-7"
                    />
                    <img
                      src="https://dev.rapsap789.com/public/logo_asset/MasterCard_Logo_svg.png"
                      alt="MASTER_CARD"
                      className="mx-1 rounded-lg max-w-7 max-h-7"
                    />
                  </div>
                </div>
                <div className="flex items-start space-x-2">
                  <RadioGroupItem value="qr" id="r2" />
                  <div className="flex flex-col">
                    <div className="flex flex-row !justify-start items-center ">
                      <Label htmlFor="r2" className="text-base font-bold">
                        ชำระเงินสแกน QR Code ผ่าน Mobile Banking
                      </Label>
                    </div>
                    <p className="font-medium mt-3">หลังจากกด "ชำระเงิน"</p>
                    <ol className="font-light ml-4 my-3 list-decimal">
                      <li>แคปเจอร์ภาพหน้าจอ QR Code ไว้</li>
                      <li>เปิดแอปธนาคารแล้วชำระเงินผ่านการสแกน</li>
                      <li>
                        กลับมาที่หน้าเว็บไซต์นี้อีกครั้งเพื่อดาวน์โหลดรูปภาพ
                      </li>
                    </ol>
                  </div>
                </div>
              </RadioGroup>
              <div className="items-top flex space-x-2 py-3.5">
                <Checkbox
                  id="terms1"
                  onCheckedChange={checked => {
                    return setConsent(!!checked)
                  }}
                />
                <div className="grid gap-1.5 leading-none">
                  <Label
                    htmlFor="terms1"
                    className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    อ่านและยอมรับเงื่อนไขการใช้งาน
                  </Label>
                  <div className="text-muted-foreground">
                    <Dialog>
                      <DialogTrigger>
                        <p className="text-blue-500 underline">
                          ข้อกำหนดและเงื่อนไข
                        </p>
                      </DialogTrigger>
                      <DialogContent className="sm:max-w-[80vw] w-full p-8">
                        <ScrollArea className="sm:max-w-[80vw] sm:max-h-[80vh] h-[80vh] rounded-md pr-2">
                          <DialogHeader>
                            <DialogTitle className="my-2">
                              Privacy Policy
                            </DialogTitle>
                            <DialogDescription className="grid gap-y-2">
                              <p>
                                นโนบายคุ้มครองข้อมูลส่วนบุคคลของ รับทรัพย์789{' '}
                              </p>
                              <p>
                                บริษัท รับทรัพย์ 789 จำกัด (ต่อไปนี้จะเรียกว่า
                                ‘บริษัท’) เป็นผู้ให้บริการเว็บไซต์
                                www.rapsap789.com
                                และคอนเทนต์บนโซเชียลมีเดียช่องทางอื่นของบริษัท
                              </p>
                              <p>
                                บริษัทตระหนักถึงความปลอดภัยของข้อมูลส่วนบุคคลของผู้ใช้งานเว็บไซต์
                                จึงมีนโยบายคุ้มครองข้อมูลส่วนบุคคลเพื่อรักษาข้อมูลที่ได้รับจากท่านไว้เป็นความลับ
                                และป้องกันความปลอดภัยของข้อมูลดังกล่าว
                                โดยทางบริษัทขอชี้แจงให้ทราบเกี่ยวกับนโยบายการเก็บข้อมูลและการใช้ข้อมูลส่วนบุคคลไว้
                                ดังนี้
                              </p>
                            </DialogDescription>
                          </DialogHeader>
                          <DialogHeader>
                            <DialogTitle className="my-2">
                              การเก็บข้อมูลและการใช้ข้อมูลส่วนบุคคล
                            </DialogTitle>
                            <DialogDescription className="grid gap-y-2">
                              <p>
                                ‘ข้อมูลส่วนบุคคล’ ในที่นี้หมายถึง
                                ข้อมูลส่วนบุคคลที่ได้รับการยินยอมจากท่าน
                                ผ่านทางเว็บไซต์ www.rapsap789.com ไม่ว่าจะเป็น
                                การซื้อสินค้าดิจิตัล เช่น ชื่อ นามสกุล อีเมล
                                เบอร์โทรศัพท์ ไอดีไลน์ วันเกิด ลัคนาราศี
                                และ/หรือข้อมูลอื่นๆ
                                และข้อมูลลักษณะการใช้งานบนเว็บไซต์ (Cookies)
                                บริษัทตกลงจะเก็บรักษาข้อมูลเหล่านี้ไว้เป็นความลับ
                                โดยบริษัทอาจมีการขอใช้ข้อมูลดังกล่าวเพื่อการใช้งานดังต่อไปนี้
                              </p>
                              <p>
                                บริษัทขอใช้ของมูลของท่านในการพัฒนาและปรับปรุงคุณภาพการให้บริการเว็บไซต์
                                www.rapsap789.com
                                คอนเทนต์ช่องทางโซเชียลมีเดียอื่น ๆ
                                เพื่อสร้างประสบการณ์ที่ดีในการเข้าใช้งานเว็บไซต์
                              </p>
                              <p>
                                บริษัทขอใช้ข้อมูลของท่านในการพัฒนาสินค้าดิจิตัล
                                ให้สอดคล้องกับความต้องการของท่านได้มากที่สุด
                              </p>
                              <p>
                                บริษัทขอใช้ข้อมูลของท่านในการสื่อสารกับท่านทางข้อความทางมือถือ
                                ข้อความทางไลน์ และข้อความทางโซเชียลมีเดีย
                              </p>
                              <p>
                                บริษัทขอใช้ข้อมูลของท่านในการแนะนำและประชาสัมพันธ์ข้อมูลข่าวสารต่าง
                                ๆ รวมทั้งสำรวจความคิดเห็นของท่าน
                              </p>
                              <p>
                                บริษัทขอใช้ข้อมูลของท่านในแนะนำข้อเสนอกิจกรรม
                                และสร้างกิจกรรมส่งเสริมการขายสินค้าหรือบริการต่าง
                                ๆ ของบริษัทและพันธมิตร
                              </p>
                            </DialogDescription>
                          </DialogHeader>
                          <DialogHeader>
                            <DialogTitle className="my-2">
                              การเปิดเผยข้อมูลส่วนบุคคลแก่บุคคลภายนอก
                            </DialogTitle>
                            <DialogDescription className="grid gap-y-2">
                              <p>
                                บริษัทจะเก็บรักษาข้อมูลส่วนบุคคลนี้ไว้เป็นความลับ
                                และไม่เปิดเผยข้อมูลดังกล่าวแก่บุคคลภายนอก
                                ยกเว้นในกรณีได้รับความยินยอมจากท่าน หรือ
                                เป็นการเปิดเผยข้อมูลบางส่วนให้กับบริการอื่นที่จำเป็นต้องทราบ
                                เช่น ผู้ให้บริการด้านการชำระเงิน
                                หรือผู้ให้บริการด้านอีเมล ด้านข้อความ
                                เพื่อสนับสนุนการใช้งานเว็บไซต์ของท่าน
                              </p>
                              <p>
                                การเปิดเผยข้อมูลบางส่วน
                                เมื่อมีการร้องขอตามคำสั่งทางกฎหมาย
                                จากหน่วยงานทางกฎหมาย เพื่อตรวจสอบความปลอดภัย
                                และปกป้องสิทธิ ทรัพย์สิน และความปลอดภัยของบริษัท
                                พนักงาน ผู้ใช้เว็บไซต์และลูกค้าองค์กรท่านอื่น
                              </p>
                            </DialogDescription>
                          </DialogHeader>
                          <DialogHeader>
                            <DialogTitle className="my-2">
                              ข้อมูลที่ท่านมอบให้กับทางบริษัท
                            </DialogTitle>
                            <DialogDescription className="grid gap-y-2">
                              <p>
                                ในการระบุข้อมูลใดๆ บนหรือผ่านเว็บไซต์บริษัทฯ
                                และ/หรือ บริษัทในเครือ หรือผู้ให้บริการ
                                ท่านมีความรับผิดชอบดังต่อไปนี้
                              </p>
                              <p>ท่านเป็นผู้มีกรรมสิทธิ์ในข้อมูลนั้น</p>
                              <p>ข้อมูลนั้นเป็นความจริงและถูกต้อง</p>
                              <p>
                                ข้อมูลนั้นจะไม่ก่อให้เกิดความเสียหายต่อบุคคลหรือนิติบุคคลใด
                              </p>
                              <p>
                                ข้อมูลนั้นมิใช่ข้อมูลที่ได้มาโดยการละเมิดลิขสิทธิ์
                                เครื่องหมายการค้า กรรมสิทธิ์ หรือสิทธิ์ในการ
                                เผยแพร่และความเป็นส่วนตัว
                              </p>
                              <p>
                                ข้อมูลนั้นต้องไม่มีลักษณะสบประมาท กล่าวร้าย
                                ดูถูก เกลียดชัง หรือก่อให้เกิดความขัดแย้งทาง
                                ศาสนา หรือคุกคามบุคคลหนึ่งบุคคลใด
                              </p>
                              <p>
                                ข้อมูลนั้นต้องไม่อ้างถึงเว็บไซต์ ที่อยู่อีเมล์
                                ที่อยู่ติดต่อ
                                หรือหมายเลขโทรศัพท์ของเว็บไซต์หรือบุคคลอื่น
                              </p>
                              <p>
                                หากข้อมูลนั้นมีความคล้ายคลึงหรือมีการระบุชื่อของบุคคลผู้มีตัวตน
                                ท่านต้องได้รับความยินยอม
                                จากบุคคลดังกล่าวในการระบุความ คล้ายคลึง
                                ชื่อของบุคคลดังกล่าวในข้อมูล
                              </p>
                            </DialogDescription>
                          </DialogHeader>
                          <DialogHeader>
                            <DialogTitle className="my-2">
                              การแก้ไขนโยบายคุ้มครองข้อมูลส่วนบุคคลและความรับผิดชอบ
                            </DialogTitle>
                            <DialogDescription className="grid gap-y-2">
                              <p>
                                บริษัทมีการดำเนินการทางเทคนิคในการรักษาความปลอดภัยของข้อมูลส่วนบุคคลทั้งผู้ที่ใช้บริการเว็บไซต์และลูกค้าองค์กร
                                เพื่อป้องกันเข้าถึงข้อมูลโดยไม่ได้รับอนุญาต
                                การเปิดเผย และปรับเปลี่ยนข้อมูลส่วนบุคคล{' '}
                              </p>
                              <p>
                                ทั้งนี้บริษัทขอสงวนสิทธิ์ในการปรับปรุงหรือแก้ไขนโยบายคุ้มครองข้อมูลส่วนบุคคลโดยมิได้แจ้งให้ทราบล่วงหน้า
                                โดยบริษัทแนะนำให้ผู้ใช้บริการเว็บไซต์และลูกค้าองค์กรทุกท่าน
                                อ่านนโยบายคุ้มครองข้อมูลส่วนบุคคลที่มีการแก้ไขปรับปรุงใหม่
                                ผ่านทางเว็บไซต์ www.rapsap789.com
                                เนื่องจากเนื้อหาทั้งหมดในหน้าเว็บไซต์นี้
                                จะเป็นเนื้อหาที่ปรับปรุงล่าสุด
                                ที่ทางบริษัทแจ้งให้ทราบทุกครั้งเมื่อมีการปรับปรุง
                              </p>
                            </DialogDescription>
                          </DialogHeader>
                          <DialogHeader>
                            <DialogTitle className="my-2">
                              การตรวจสอบข้อมูลส่วนตัวของท่านและการยกเลิกการใช้ข้อมูล
                            </DialogTitle>
                            <DialogDescription className="grid gap-y-2">
                              <p>
                                หากท่านต้องการตรวจสอบการใช้หรือเปิดเผยข้อมูลส่วนบุคคล
                                รวมถึงยกเลิกความยินยอมในการให้ บริษัท รับทรัพย์
                                789 จำกัด ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
                                สามารถแจ้งข้อมูลส่วนตัวที่ท่านต้องการยกเลิกได้ผ่านทาง
                                LINE รับทรัพย์789 หรือ อีเมล rapsap789@gmail.com
                              </p>
                            </DialogDescription>
                          </DialogHeader>
                        </ScrollArea>
                      </DialogContent>
                    </Dialog>
                  </div>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
      <div className="fixed bottom-0 rounded-t-2xl h-[80px] flex flex-col justify-center w-full bg-white shadow-next-button">
        <div className="m-2">
          <Button
            className="bg-dark-red hover:bg-dark-red min-w-[200px] w-full rounded-md font-bold text-sm text-gold"
            disabled={!consent}
            onClick={goToPaymentMethod}
          >
            ชำระเงิน
          </Button>
        </div>
      </div>
    </>
  )
}

export default PaymentPage
