'use client'
import { useEffect, useRef, useState } from 'react'
import DatePicker from "react-datepicker"
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import th from 'date-fns/locale/th'

import { Button } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from '@/components/ui/select';
import NotFoundError from '@/components/common/notFoundError'

import { API_URL } from '@/config'
import { inAppRouteList, zodiacs } from '@/constants'
import { UserActionTypes, useUserContext } from '@/providers'
import { UserContext, Zodiac } from '@/providers/user/types'
import { calculateZodiac } from '@/utils/customerHelper'
import { setUserInfo } from '@/utils/localStorage'
import { useGalleryContext } from '@/providers/gallery'
import { usePackageContext } from '@/providers/package'

import "@/styles/react-datepicker.css"

const setInputBorderColor = (e: React.FocusEvent<HTMLInputElement, Element>) => {
  const target = e.currentTarget as HTMLInputElement;
  if (target && target.value) {
    target.style.borderColor = 'rgb(226, 232, 240)'
  }
}

const CustomerInfoPage = () => {
  const navigate = useNavigate()

  const { context: userContext, dispatch: userDispatch } = useUserContext()
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)

  const [zodiac, setZodiac] = useState<Zodiac>('')
  const [date, setDate] = useState<Date>(new Date())

  const { context: galleryContext } = useGalleryContext()
  const { context: packageContext } = usePackageContext()

  const location = useLocation()
  const productId = location.state.productId
  const itemId = location.state.itemId

  if (!(productId && itemId)) return <Navigate to={inAppRouteList.ProductList} />

  // When selectedProduct is undefined, this page should return error
  const selectedProduct = galleryContext.find(g => g._id == productId)
  if (!(selectedProduct && selectedProduct.title)) {
    return <NotFoundError msg="Gallery" cta={true} />
  }

  // When packageItem is undefined, this page should return error
  const packageItem = packageContext.find(pkg => pkg.galleryId == productId)
  if (!(packageItem && packageItem.imgOriginal)) {
    return <NotFoundError msg="Package" cta={true} />
  }

  // When selectedPackageItem is undefined, this page should return error
  const selectedPackageItem = packageItem.items[Number(itemId)]
  if (!(selectedPackageItem && selectedPackageItem.imageSuffix)) {
    return <NotFoundError msg="Package Item" cta={true} />
  }

  const imageDirectory = `${packageItem.imgOriginal}${selectedPackageItem.imageSuffix}`
  // TODO: verify then remove this variable
  const previewImage = `${API_URL}/images/${selectedProduct.galleryName}/${imageDirectory}/watermark`

  useEffect(() => {
    if (date) {
      setZodiac(calculateZodiac(date))
    }
  }, [date])

  const goToCart = () => {
    let firstName = ''
    let lastName = ''

    // advance input validation https://ui.shadcn.com/docs/components/form
    // basic input validation sample
    if (!firstNameRef.current) return
    if (!firstNameRef.current.value) {
      firstNameRef.current.style.borderColor = 'red'
      return
    }

    firstName = firstNameRef.current.value

    if (!lastNameRef.current) return
    if (!lastNameRef.current.value) {
      lastNameRef.current.style.borderColor = 'red'
      return
    }

    lastName = lastNameRef.current.value

    const data: UserContext = {
      uid: userContext.uid,
      isConsent: userContext.isConsent,
      galleryName: selectedProduct.galleryName,
      galleryId: productId,
      itemId,
      firstName,
      lastName,
      birthDate: date,
      zodiac,
      previewImage,
      imageDirectory
    }

    userDispatch({ type: UserActionTypes.AddUserInfo, payload: data })
    setUserInfo(JSON.stringify(data))
    navigate('/cart')
  }

  return (
    <>
      <div className="flex-col my-4 items-center justify-center h-12">
        <p className="font-medium text-xl text-center">
          กรอกข้อมูลเพื่อสลักชื่อ-นามสกุล
        </p>
        <p className="font-medium text-xl text-center">
          และเลขมงคลของท่านลงบนภาพ
        </p>
      </div>
      <Card className='w-[420px] bg-neutral-100 py-6 shadow-xl'>
        <CardContent className='grid gap-4'>
          <div className='grid w-full max-w-sm items-center gap-1.5'>
            <Label htmlFor='firstName' className='text-sm'>ชื่อ</Label>
            <Input
              id='firstName'
              type='text'
              className='bg-white'
              ref={firstNameRef}
              defaultValue={userContext.firstName}
              onBlur={setInputBorderColor}
            />
          </div>
          <div className='grid w-full max-w-sm items-center gap-1.5'>
            <Label htmlFor='lastName' className='text-sm'>นามสกุล</Label>
            <Input
              id='lastName'
              type='text'
              className='bg-white'
              ref={lastNameRef}
              defaultValue={userContext.lastName}
            />
          </div>
          <div className='grid w-full max-w-sm items-center gap-1.5'>
            <Label htmlFor='birthDate' className='text-sm'>
              วัน เดือน ปีเกิดแบบ ค.ศ. (วว-ดด-ปปปป)
            </Label>
            <DatePicker
              id='birthDate'
              className='flex h-10 w-full rounded-md border border-input px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 bg-white'
              selected={date}
              locale={th}
              onChange={(selectedDate: Date) => setDate(selectedDate)}
              showMonthDropdown
              showYearDropdown
              dateFormat="dd-MM-yyyy"
              dropdownMode='select'
            />
          </div>
          <div className='grid w-full max-w-sm items-center gap-1.5'>
            <Label className='text-sm'>ราศี</Label>
            <Select
              value={zodiac}
              defaultValue={userContext.zodiac}
              onValueChange={
                (e: Zodiac) => {
                  setZodiac(e)
                }}
            >
              <SelectTrigger className='w-full bg-white'>
                <SelectValue />
              </SelectTrigger>
              <SelectContent className="bg-white">
                <SelectGroup>
                  <SelectLabel>ราศี</SelectLabel>
                  {
                    zodiacs.map((zodiac) => (
                      <SelectItem value={zodiac.value} key={zodiac.title}>
                        {zodiac.title}
                      </SelectItem>
                    ))
                  }
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        </CardContent>
      </Card>
      <div className="fixed bottom-0 rounded-t-2xl h-[80px] flex flex-col justify-center w-full bg-white shadow-next-button">
        <div className="m-2">
          <Button
            className="bg-dark-red hover:bg-dark-red min-w-[200px] w-full rounded-md font-bold text-sm text-gold"
            onClick={goToCart}
          >
            ดูตัวอย่างภาพ
          </Button>
        </div>
      </div>
    </>
  )
}

export default CustomerInfoPage
