import { inAppRouteList, inAppRoutePathParamList } from '@/constants'

export const getProductDetailUrl = (productId: string) => inAppRouteList
  .ProductDetail
  .replace(inAppRoutePathParamList.ProductId, productId)

export const getToItemListUrl = (productId: string) => inAppRouteList
  .ItemList
  .replace(inAppRoutePathParamList.ProductId, productId)

export const getToCustomerInfo = () => inAppRouteList
  .CustomerInfo

export const getToCart = () => inAppRouteList
  .Cart

export const getToPreview = () => inAppRouteList
  .Preview

export const getToPaymentGateway = () => inAppRouteList
  .PaymentGateway

export const getToPaymentSuccess = () => inAppRouteList
  .PaymentSuccess
